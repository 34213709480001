import React from 'react';
import { useEffect } from 'react';

import './index.css';
import { BrowserRouter, Routes } from "react-router-dom";
import { routes } from "./routes/routes"
import ScrollToTop from "./ScrollToTop"
import { useState } from "react"
import ADNavbar from "./dist/ADNavbar"
import { ConditionalSnow } from './dist/helpers';

export default function Index() {
  const systemDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [isDarkModeOverride, setDarkModeOverride] = useState(systemDarkMode);
  // TODO - Add the ability such that when the current month falls between Nov through March auto on
  const [snowIsEnabled, setSnowIsEnabled] = useState(false);

  const darkModeToggle = () => (
    <button
      className={`btn`}
      type="button"
      onClick={() => setDarkModeOverride(!isDarkModeOverride)}
      style={{ marginTop: "0rem" }}
    >
      {isDarkModeOverride ? "☀️" : "🌙"}
    </button>
  );

  const snowToggle = () => (
    <button
      className={`btn`}
      type="button"
      onClick={() => setSnowIsEnabled(!snowIsEnabled)}
      style={{ marginTop: "0rem" }}
    >
      {snowIsEnabled ? "☁️" : "❄️"}
    </button>
  );


  // This is for determining distance from top of page
  let [shouldRemoveTransparent, setShouldRemoveTransparent] = useState(false);

  useEffect(() => {
    const handleScroll = event => {
      // console.log('window.scrollY', window.scrollY);
      const distanceFromTop = document.documentElement.scrollTop
      if (distanceFromTop > 70) {
        setShouldRemoveTransparent(true);
      } else {
        setShouldRemoveTransparent(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  // On page visit, ping server
  sendUserMetadata();

  return (
    <div
      style={{ backgroundColor: isDarkModeOverride ? "rgb(105, 159, 201)" : "#A0CFEB" }}>
      <React.StrictMode>
        <BrowserRouter>
          <ScrollToTop />
          <br />
          <ADNavbar {...{
            isDarkModeOverride,
            darkModeToggle,
            shouldRemoveTransparent,
            snowToggle
          }} />
          {ConditionalSnow(snowIsEnabled)}
          {ConditionalSnow(snowIsEnabled)}
          <Routes>
            {routes(isDarkModeOverride)}
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </div>
  )
};


// Extended version with more metadata options
async function sendUserMetadata() {
  try {
    const metadata = {
      timestamp: new Date().toISOString(),
      userAgent: navigator.userAgent,
      language: navigator.language,
      screen: {
        width: window.screen.width,
        height: window.screen.height,
        colorDepth: window.screen.colorDepth
      },
      location: {
        href: window.location.href,
        hostname: window.location.hostname,
        pathname: window.location.pathname
      },
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      // Additional optional metadata
      cookiesEnabled: navigator.cookieEnabled,
      platform: navigator.platform,
      connection: navigator.connection ? {
        effectiveType: navigator.connection.effectiveType,
        rtt: navigator.connection.rtt,
        downlink: navigator.connection.downlink
      } : null,
      referrer: document.referrer
    };

    const response = await fetch('http://10.0.0.101:8080/api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors', // Enable CORS if needed
      credentials: 'same-origin', // Include credentials if needed
      body: JSON.stringify(metadata)
    });

    console.log(JSON.stringify(metadata))

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log('Server response:', responseData);

  } catch (error) {
    console.error('Error sending metadata:', error);
  }
}

async function getLogCountWithFetch() {
  try {
    const response = await fetch('http://10.0.0.101:8080/count', { method: 'GET', headers: { 'Content-Type': 'application/json', } });
    if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
    const data = await response.json(); console.log('Number of log entries:', data.count);
    return data.count;
  } catch (error) {
    console.error('Error fetching count:', error);
    return null;
  }
}

export async function getCounter() {
  const count1 = await getLogCountWithFetch();
  if (count1 !== null) {
    return count1;
  }
  return "Error";
}
