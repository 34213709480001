import React, { useState, useEffect } from 'react';
import './App.css'
import { space6 } from './dist/helpers'
import { LandingPage } from './dist/LandingPage'
import { getCounter } from './index.jsx'
import styled from 'styled-components';

const Loader = styled.div`
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

function App(props) {
  console.log("Welcome to Andrew Dilks .com!!")
  const { finalIsDarkMode } = props;
  const [visits, setVisits] = useState("loading");

  useEffect(() => {
    // Fetch the count when component mounts
    getCounter().then(count => {
      setVisits(count);
    });
  }, []); // Empty dependency array means it runs once on mount

  return (
    <>
      <div>
        { LandingPage(finalIsDarkMode) }
        <>
          <center>
            <h1> Number visits: {visits} { visits === "loading" ? (<Loader />): (<></>)} </h1>
          </center>
        </>
        { space6 }
        { space6 }
      </div>
    </>
  )
}

export default App;
